:root {
  --font-primary: 'Roboto', sans-serif;
  --color-primary: rgb(15 76 129);
  --color-light-blue: #1890ff;
  --font-color-secondary: #9197a3;
  --font-color-table: #535c6f;
  --border-color-primary: #e8e8e8;
  --background-color-app: #f0f2f5;
  --background-color-grey: #f5f5f5;

  --box-shadow-level-1: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12),
    0 3px 1px -2px rgba(0, 0, 0, 0.2);
  --box-shadow-level-2: 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12),
    0 2px 4px -1px rgba(0, 0, 0, 0.4);
  --box-shadow-level-3: 0 6px 10px 0 rgba(0, 0, 0, 0.14), 0 1px 18px 0 rgba(0, 0, 0, 0.12),
    0 3px 5px -1px rgba(0, 0, 0, 0.4);
  --box-shadow-level-4: 0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12),
    0 5px 5px -3px rgba(0, 0, 0, 0.4);

  --z-index-10: 10;
  --z-index-20: 20;
  --z-index-30: 30;
  --z-index-40: 40;
  --z-index-50: 50;
}

body {
  min-height: 100vh;
  min-width: 320px;
  margin: 0;
  background-color: var(--background-color-grey);
  overflow-x: hidden;
  font-family: var(--font-primary) !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, monospace;
}

.custom-option {
  font-size: 11px !important;
}

.ant-message-notice {
  text-align: right !important;
  padding-right: 25px !important;
}

.ant-table-row-expand-icon {
  margin-left: 15px !important;
}

.network-column {
  background: white !important;
}
